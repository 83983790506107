




















































































































































































































































































































































































































.manage{
  .el-table .el-table__cell .cell{
    line-height: 1.4em !important;
  }
  .el-table .el-table__cell{
    padding: 0px 5px !important;
  }
}







































































































































































































































































































































































.edposter {
  ::v-deep {
    .el-dialog__body {
      padding: 20px;
    }
    .progress {
      width: 360px;
    }
  }
}
.footer {
  text-align: center;
}
::v-deep .edposter {
  .upload-two{
    .el-upload-dragger{
      width: 142px;
      height: 80px;
    }
  }
  .hiddenborder{
    .el-upload-dragger{
     border: none!important;
    }
  }
  .upicon{
    font-size: 26px;
    top: 2px;
    position: relative;
    margin-right: 6px;
    color: #19B0B0;
  }
  .up_class{
    padding:55px 0px 0px;
    position: relative;
    .up_title{
      font-size: 16px;
      font-weight: bold;
      color: #19B0B0;
      line-height: 17px;
    }
    .up_con{
      margin-top: 12px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      text-align: left;
      padding-left: 147px;
    }
  }
  .uimg-conent{
    position: relative;
    padding: 22px 0;
    box-sizing: border-box;
    .l-h-20{
      line-height: 20px;
    }
    .i-font{
      font-size: 12px;
      font-weight: 400;
      color: #19B0B0;
      line-height: 11px;
      margin-top: 5px;
    }
  }
  .up_img{
    width: 142px;
    height: 80px;
    img{
      width: 100%;
      height: 100%;
    }
    .baclk_back{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, .6);
    }
    .icon_pos{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #FFFFFF;
      .white{
        color: #FFFFFF;
        font-size: 22px;
        margin-bottom: 7px;
      }
    }
  }
  .cover_progess{
    position: absolute;
    top: 0;
    left: 8%;
    .el-progress-circle{
      width: 80px!important;
      height: 80px!important;
    }
  }
}
.redreq{
  color: #F56C6C;
  margin-right: 4px;
}
